<template>
    <div class="my-3 container" v-if="invoice">
        <div class="row">
            <div class="col-6 pt-4">
                <h1>Seller Invoice</h1>
                <h3>Invoice ID: {{invoice_id}}</h3>
                <h5>Date: {{invoice.auction.date}}</h5>
                <p class="lead">
                    <small style="font-weight:bold;">INVOICE TO</small><br/>
                    {{invoice.customer.first_name}} {{invoice.customer.last_name}}<br/>
                    {{invoice.customer.address}}<br/>
                    {{invoice.customer.post_code}}<br/>
                    {{invoice.customer.telephone}}
                </p>
            </div>
            <div class="col-6 text-end">
                <img src="../assets/logo.jpg" style="max-height:140px;margin-bottom:5mm;"/>
                <div>Mobile: 07926 196066</div>
                <div>Email: yhillauction@gmail.com</div>
            </div>
        </div>
        <hr/>
    </div>

    <div class="my-3 container pb-5" v-if="invoice">
        <table class="table table-striped">
        <thead>
            <tr>
                <th class="shrink">Lot ID</th>
                <th>Description</th>
                <th class="shrink text-end">Sale Price</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="lot in invoice.lots" :key="lot.id">
                <td class="shrink">{{lot.lot_no}}</td>
                <td>{{lot.description}}</td>
                <td class="shrink text-end">£{{lot.hammer.toFixed(2)}}</td>
            </tr>
            <tr>
                <td colspan="2" class="text-end">Sub Total</td>
                <td class="shrink text-end">£{{subtotal}}</td>
            </tr>
            <tr>
                <td colspan="2">
                    <div class="d-flex align-items-center justify-content-end">
                        £<input type="text" v-model="lot_charge" class="form-control mx-2" style="width: 100px;"/> 
                        charge per lot
                    </div>
                </td>
                <td class="shrink text-end">-£{{total_lot_charge}}</td>
            </tr>
            <tr>
                <td colspan="2" class="text-end">
                    <div class="d-print-none">
                        Adjustment <input class="form-control d-inline" style="width:400px;" v-model="adjustment_description" placeholder="Description"/> <input class="form-control d-inline" style="width:100px;" v-model="adjustment_value" placeholder="0.00"/>
                    </div>
                    <div class="d-none d-print-block">
                        Adjustment: {{adjustment_description}}
                    </div>
                </td>
                <td class="shrink text-end ">£{{adjustment_value}}</td>
            </tr>
            <tr>
                <td colspan="2" class="text-end">{{commission_percentage}}% Commission</td>
                <td class="shrink text-end">-£{{total_commission}}</td>
            </tr>
            <tr style="font-weight:bold;">
                <td colspan="2" class="text-end">Total</td>
                <td class="shrink text-end">£{{total}}</td>
            </tr>
        </tbody>
        </table>
        <div class="text-end d-print-none">
            <button v-if="validToSave" @click="save" class="btn btn-primary me-2">Save</button> 
        </div>
    </div>

    <Modal :show="error!=''" title="Error">
        {{error}}
        <template v-slot:footer>
            <button @click="error=''" class="btn btn-sm btn-primary">Ok</button>
        </template>
    </Modal>
</template>

<script>
import _ from 'lodash'
export default{
    name: 'SellerInvoice',
    props:{
        auction_id: String,
        customer_id: String
    },
    data(){
        return {
            invoice: null,
            commission_percentage: 17.5,
            lot_charge: 1.00,
            adjustment_description: '',
            adjustment_value: 0.00,
            error: ''
        }
    },
    computed:{
        subtotal(){
            var total = 0;
            this.invoice.lots.forEach(lot=>{
                total += lot.hammer
            });
            return total.toFixed(2);
        },
        total_commission(){
            return (parseFloat(this.subtotal)*(parseFloat((this.commission_percentage ? this.commission_percentage : 0))/100)).toFixed(2);
        },
        total_lot_charge(){
            return (this.lot_charge ? (this.lot_charge*this.invoice.lots.length) : 0).toFixed(2);
        },
        total(){
            var total = this.subtotal;
            total = total-this.total_commission;
            total = total-this.total_lot_charge;
            total = total+(this.adjustment_value ? parseFloat(this.adjustment_value) : 0);
            return total.toFixed(2);
        },
        validToSave(){
            if(!_.isEmpty(this.invoice.invoice)){
                if(parseFloat(this.invoice.invoice.lot_charge) == parseFloat(this.lot_charge) && parseFloat(this.invoice.invoice.adjustment_value) == parseFloat(this.adjustment_value) && this.invoice.invoice.adjustment_description == this.adjustment_description){
                    return false;
                }
            }else if(this.lot_charge == ''){
                return false;
            }
            return true;
        },
        invoice_id(){
            return this.invoice.auction.id+''+this.customer_id;
        }
    },
    methods:{
        getInvoice(){
            this.axios.get(this.apiUrl(`seller_invoice/${this.auction_id}/${this.customer_id}`), this.tokenHeader).then(response=>{
                this.invoice = response.data
                if(!_.isEmpty(this.invoice.invoice)){
                    if(this.invoice.invoice.lot_charge != ''){
                        this.lot_charge = this.invoice.invoice.lot_charge
                    }
                    if(this.invoice.invoice.adjustment_description != ''){
                        this.adjustment_description = this.invoice.invoice.adjustment_description
                    }
                    if(this.invoice.invoice.adjustment_value != ''){
                        this.adjustment_value = this.invoice.invoice.adjustment_value
                    }
                }
                /* if(!_.isEmpty(this.invoice.invoice)){ */
                /*     this.commission_percentage = this.invoice.invoice.commission */
                /* } */
            });
        },
        save(){
            this.axios.post(this.apiUrl(`seller_invoice/${this.auction_id}/${this.customer_id}`), {
                lot_charge: this.lot_charge,
                commission: 0,
                adjustment_description: this.adjustment_description,
                adjustment_value: this.adjustment_value
            }, this.tokenHeader).then(response=>{
                if(response.data.status == 'success'){
                    this.getInvoice();
                }else{
                    this.error = 'Error saving invoice';
                }
            });
        }
    },
    mounted(){
        this.getInvoice();
    }
}
</script>
