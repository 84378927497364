<template>
    <Navbar page="buyer_invoices"/>
    <div class="container">
        <input type="text" class="form-control my-3" style="width:200px;" placeholder="Customer Number" v-model="search"/>

        <table class="table table-striped table-hover align-middle">
        <thead>
        <tr>
            <th class="shrink">Auction ID</th>
            <th class="shrink">Auction Date</th>
            <th class="shrink">Buyer ID</th>
            <th class="shrink">Buyer Name</th>
            <th>Telephone</th>
            <th class="shrink">Lot(s)</th>
            <th class="shrink">Hammer Total</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="invoice in filteredInvoices" :key="invoice.id" @click="selectInvoice(invoice)">
            <td class="shrink">{{invoice.auction.id}}</td>
            <td class="shrink">{{invoice.auction.date}}</td>
            <td class="shrink">{{invoice.customer.id}}</td>
            <td class="shrink">{{invoice.customer.first_name}} {{invoice.customer.last_name}}</td>
            <td>{{invoice.customer.telephone}}</td>
            <td class="shrink">{{invoice.lots.length}}</td>
            <td class="shrink">{{sumHammer(invoice.lots).toFixed(2)}}</td>
        </tr>
        </tbody>
        </table>
    </div>
</template>
<script>
import Navbar from '../components/Navbar'
/* import _ from 'lodash' */
export default{
    name: 'BuyerInvoices',
    components:{
        Navbar
    },
    data(){
        return {
            invoices: [],
            search: ''
        }
    },
    mounted(){
        this.getInvoices();
    },
    computed:{
        filteredInvoices(){
            if(this.search == ""){
                return this.invoices;
            }
            return this.invoices.filter(invoice=>{
                var found = false;

                if(invoice.customer.id == this.search){
                    found = true;
                }
                /* _.forEach(invoice.customer, (value)=>{ */
                /*     if(!found && value.toString().toLowerCase().includes(this.search.toLowerCase())){ */
                /*         found=true; */
                /*     } */
                /* }) */

                if(found){
                    return true;
                }

                return false;
            });
        }
    },
    methods:{
        selectInvoice(invoice){
            this.$router.push(`/buyer_invoice/${invoice.auction.id}/${invoice.customer.id}`)
        },
        sumHammer(lots){
            var total = 0;
            lots.forEach(lot => {
                total += lot.hammer
            })
            return total;
        },
        getInvoices(){
            this.axios.get(this.apiUrl('buyer_invoices'), this.tokenHeader).then(response=>{
                this.invoices = response.data
            });
        }
    }
}
</script>
