import { createWebHistory, createRouter } from "vue-router";
import store from './store'
import Login from "./views/Login.vue";
import Customers from "./views/Customers.vue";
import Auctions from "./views/Auctions.vue";
import Lots from "./views/Lots.vue";
import Settings from "./views/Settings.vue";
import SellerInvoices from "./views/SellerInvoices.vue";
import PrintSellerInvoice from "./views/PrintSellerInvoice.vue";
import BuyerInvoices from "./views/BuyerInvoices.vue";
import PrintBuyerInvoice from "./views/PrintBuyerInvoice.vue";
import PrintAuction from "./views/PrintAuction.vue";

const routes = [
    {
        path: "/login",
        name: "Login",
        component: Login
    },
    {
        path: "/customers",
        name: "Customers",
        component: Customers
    },
    {
        path: "/auctions",
        name: "Auctions",
        component: Auctions
    },
    {
        path: "/lots/auction/:auction_id",
        component: Lots,
        props: true
    },
    {
        path: "/lots/seller/:seller_id",
        component: Lots,
        props: true
    },
    {
        path: "/lots",
        name: "Lots",
        component: Lots
    },
    {
        path: "/settings",
        name: "Settings",
        component: Settings
    },
    {
        path: "/seller_invoices",
        name: "SellerInvoices",
        component: SellerInvoices
    },
    {
        path: "/seller_invoice/:auction_id/:customer_id",
        component: PrintSellerInvoice,
        props: true
    },
    {
        path: "/buyer_invoices",
        name: "BuyerInvoices",
        component: BuyerInvoices
    },
    {
        path: "/buyer_invoice/:auction_id/:customer_id",
        component: PrintBuyerInvoice,
        props: true
    },
    {
        path: "/auction/print/:auction_id",
        component: PrintAuction,
        props: true
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    if (['Login'].indexOf(to.name) == -1 && !store.state.jwt){
        next({ name: 'Login' })
    }else{
        next();
    }
});

export default router;

